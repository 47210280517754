<template>
  <div class="standalone-hosting-section">
    <div class="initial-prompt-section">
      <div>
        <div class="mt-4">
          <b-link
            class="standalone-hosting-link"
            aria-label="Standalone Hosting Link"
            @click="$emit('show-standalone-hosting-modal')"
          >
            <b-icon class="globe-icon" icon="globe2" />
            {{ "Use my own domain that already exists elsewhere." }}
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandaloneHostingSection',
}
</script>

<style lang="scss" scoped>
// Palette
$light-grey: #918F8F;

.standalone-hosting-section {

  .standalone-hosting-link {
    color: $ct-ui-color-10;
    text-decoration: underline;
  }
  .initial-prompt-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .globe-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
