var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "standalone-hosting-section" }, [
    _c("div", { staticClass: "initial-prompt-section" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c(
              "b-link",
              {
                staticClass: "standalone-hosting-link",
                attrs: { "aria-label": "Standalone Hosting Link" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("show-standalone-hosting-modal")
                  },
                },
              },
              [
                _c("b-icon", {
                  staticClass: "globe-icon",
                  attrs: { icon: "globe2" },
                }),
                _vm._v(
                  "\n          " +
                    _vm._s("Use my own domain that already exists elsewhere.") +
                    "\n        "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }